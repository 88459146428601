import React from "react";
import { FunctionComponent } from "react";
import * as Styled from "./Footer.styled";
import Logo from "../Logo/Logo";

const Footer: FunctionComponent = () => {
  return (
    <Styled.Content>
      <Styled.Container>
        <Logo type={"dark"} />
        <Styled.Wrapper>
          <Styled.InfoLeft>
            <Styled.Text>
              Pamiętaj, że zawsze możesz wycofać wyrażone zgody na przetwarzanie
              Twoich danych osobowych. Wycofanie zgód nie będzie miało jednak
              wpływu na zgodność z&nbsp;prawem naszych działań podjętych jeszcze
              przed jej wycofaniem.
            </Styled.Text>
            <br />
            <Styled.Text>
              <strong>
                Aby wycofać swoją zgodę skontaktuj się z&nbsp;nami pod adresem:{" "}
                <a href="mailto:daneosobowe@bat.com.pl" className={"purple"}>
                  daneosobowe@bat.com.pl
                </a>
                .
              </strong>
            </Styled.Text>
          </Styled.InfoLeft>
          <Styled.InfoRight>
            <Styled.Column>
              <Styled.Label>Kontakt</Styled.Label>
              <Styled.Text>
                <strong>BAT Polska Trading Sp.&nbsp;z&nbsp;o.o.</strong>
                <br />
                The Park Warsaw
                <br />
                ul. Krakowiaków 48
                <br />
                02-255 Warszawa
                <br />
                NIP: 522-291-72-10
                <br />
                REGON: 141817884
              </Styled.Text>
            </Styled.Column>
            <Styled.Column>
              <Styled.Label>Bezpłatna infolinia</Styled.Label>
              <Styled.Text className={"phone"}>800 610 610</Styled.Text>
              <Styled.Text>Czynna pn-nd: 8:00-20:00</Styled.Text>
            </Styled.Column>
            <Styled.Column>
              <Styled.Label>Regulaminy</Styled.Label>
              <Styled.Text>
                <a href="https://www.velo.com/pl/pl/polityka-cookies">
                  Polityka cookies
                </a>
              </Styled.Text>
              <Styled.Text>
                <a href="https://www.velo.com/pl/pl/polityka-prywatnosci">
                  Polityka prywatności
                </a>
              </Styled.Text>
              <Styled.Text>
                <button data-cc="c-settings" className="cc-link">
                  Preferencje cookies
                </button>
              </Styled.Text>
              <Styled.Text>
                <a
                  href={"/Regulamin-serwisu-kickstartyou.pdf"}
                  target={"_blank"}
                >
                  Regulamin serwisu
                </a>
              </Styled.Text>
            </Styled.Column>
          </Styled.InfoRight>
        </Styled.Wrapper>
        <Styled.Text>
          <strong>© 2023 BAT Polska Trading. All rights reserved.</strong>
          <br />
        </Styled.Text>
        <Styled.Text>
          Serwis został stworzony przez BAT Polska Trading Sp.&nbsp;z&nbsp;o.o.
          z&nbsp;siedzibą w&nbsp;Warszawie. Strona przeznaczona jest wyłącznie
          dla osób pełnoletnich zainteresowanych ofertą wyrobów nikotynowych BAT
          Polska Trading Sp.&nbsp;z&nbsp;o.o.
          <br />
        </Styled.Text>
      </Styled.Container>
    </Styled.Content>
  );
};

export default Footer;
