import React from "react";
import { FunctionComponent } from "react";
import * as Styled from "./Header.styled";
import Navigation from "../Navigation/Navigation";
import Button from "../Button/Button";

const Header: FunctionComponent = () => {
  return (
    <Styled.Content>
      <Navigation />
      <Styled.TextWrapper>
        <Styled.Title>
          Nie mów. <span>Działaj!</span>
        </Styled.Title>
        <Styled.Subtitle>Kickstart you</Styled.Subtitle>
      </Styled.TextWrapper>
      <Styled.ButtonWrapper>
        <Button text={`Wypełnij quiz bez gadania`} link={`#quiz`} bigger />
      </Styled.ButtonWrapper>
    </Styled.Content>
  );
};

export default Header;
