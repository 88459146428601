import React, {FunctionComponent, useEffect, useState} from "react";
import * as Styled from "./Logo.styled";

type Props = {
  type: 'light' | 'dark';
};

const Logo: FunctionComponent<Props> = ({type}) => {
  const [colors, setColors] = useState<string[]>(['white', '#6826BB', 'white']);
  useEffect(() => {
    if(type === 'light') {
      setColors(['white', '#6826BB', 'white']);
    } else {
      setColors(['#191B1F', '#14D3B2', '#6826BB']);
    }
  }, [])

  return (
    <Styled.Container>
      <svg width="224" height="37" viewBox="0 0 224 37" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.94 28.5L8.13 17.67L17.46 7.53H14.1L5.25 17.25V7.53H2.55V28.5H5.25V18.27L14.46 28.5H17.94ZM20.7141 28.5H23.4141V7.53H20.7141V28.5ZM38.437 28.98C41.977 28.98 45.247 27.51 47.377 24.63L45.187 23.19C43.507 25.29 41.107 26.37 38.437 26.37C33.637 26.37 29.977 22.74 29.977 18C29.977 13.26 33.637 9.63 38.437 9.63C41.107 9.63 43.507 10.71 45.187 12.81L47.377 11.37C45.247 8.49 41.977 7.02 38.437 7.02C32.227 7.02 27.217 11.88 27.217 18C27.217 24.12 32.227 28.98 38.437 28.98ZM65.9283 28.5L56.1183 17.67L65.4483 7.53H62.0883L53.2383 17.25V7.53H50.5383V28.5H53.2383V18.27L62.4483 28.5H65.9283ZM66.5794 25.02C67.9294 27.48 70.7194 29.01 73.9594 29.01C77.8294 29.01 80.7694 26.31 80.7694 22.74C80.7694 19.56 78.6394 17.73 75.2794 16.74L73.5094 16.2C71.4694 15.57 69.9394 14.67 69.9394 12.87C69.9394 10.92 71.5894 9.54 73.7494 9.54C75.6994 9.54 77.1094 10.29 78.0694 11.82L80.2894 10.38C79.2094 8.52 76.9894 7.05 73.8094 7.05C70.1794 7.05 67.2394 9.51 67.2394 12.9C67.2394 15.96 69.4294 17.73 72.8194 18.72L74.5294 19.23C76.6894 19.86 78.0694 20.82 78.0694 22.77C78.0694 24.93 76.3294 26.43 73.9594 26.43C71.7994 26.43 69.9094 25.53 68.6794 23.52L66.5794 25.02ZM88.0425 28.5H90.7425V10.11H95.8425L97.0125 7.53H82.3725V10.11H88.0425V28.5ZM111.437 28.5H114.257L104.327 6.96H104.267L94.3373 28.5H97.0673L99.7673 22.41H108.707L111.437 28.5ZM100.907 19.86L104.237 12.39L107.567 19.86H100.907ZM130.637 28.5L123.257 19.26H123.347C126.767 19.26 129.557 16.77 129.557 13.41C129.557 10.05 126.767 7.53 123.347 7.53H116.837V28.5H119.477V18.48L127.367 28.5H130.637ZM123.257 10.05C125.267 10.05 126.857 11.46 126.857 13.44C126.857 15.39 125.267 16.8 123.257 16.8H119.477V10.05H123.257ZM136.88 28.5H139.58V10.11H144.68L145.85 7.53H131.21V10.11H136.88V28.5Z" fill={colors[0]}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M144 2L180.929 18.5L144 35V2Z" fill={colors[1]}/>
        <path d="M180.48 7.53H175.29L171.18 14.79L167.01 7.53H161.7L168.84 19.26V28.5H173.37V19.35L180.48 7.53ZM191.199 29.01C197.409 29.01 202.419 24.15 202.419 18C202.419 11.85 197.409 6.99 191.199 6.99C184.989 6.99 179.979 11.85 179.979 18C179.979 24.15 184.989 29.01 191.199 29.01ZM191.199 24.72C187.449 24.72 184.569 21.81 184.569 18C184.569 14.19 187.449 11.28 191.199 11.28C194.949 11.28 197.829 14.19 197.829 18C197.829 21.81 194.949 24.72 191.199 24.72ZM213.269 29.01C217.829 29.01 221.459 25.47 221.459 20.97V7.53H216.929V20.97C216.929 23.07 215.339 24.72 213.269 24.72C211.169 24.72 209.549 23.07 209.549 20.97V7.53H205.019V20.97C205.019 25.47 208.679 29.01 213.269 29.01Z" fill={colors[2]}/>
      </svg>
    </Styled.Container>
  );
};

export default Logo;
