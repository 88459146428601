import * as React from "react";
import { FunctionComponent } from "react";
import MainLayout from "../layouts/MainLayout";
import { ParallaxProvider } from "react-scroll-parallax";
import Quiz from "../components/Quiz/Quiz";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import AgeGate from "../components/AgeGate/AgeGate";

const IndexPage: FunctionComponent = () => (
  <MainLayout>
    <ParallaxProvider>
      <Header />
    </ParallaxProvider>
    <main className="main-content">
      <AgeGate />
      <Quiz />
    </main>
    <Footer />
  </MainLayout>
);

export default IndexPage;
