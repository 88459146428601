import { FunctionComponent } from "react";
import * as Styled from "./Quiz.styled";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Lips1 from "../../images/lips-1.jpg";
import Lips2 from "../../images/lips-2.jpg";
import Lips3 from "../../images/lips-3.jpg";
import Lips4 from "../../images/lips-4.jpg";
import Lips5 from "../../images/lips-5.jpg";
import Lips6 from "../../images/lips-6.jpg";

const Quiz: FunctionComponent = () => {
  return (
    <Styled.Content id={"quiz"}>
      <Styled.Container>
        <Styled.TitleWrapper>
          <Styled.Quiz>Quiz</Styled.Quiz>
          <Styled.Title>
            Wybierz, którymi ustami dzisiaj jesteś
            <span>i zobacz, co na Ciebie czeka</span>
          </Styled.Title>
        </Styled.TitleWrapper>
        <Styled.Images>
          <Styled.Image to="/usta#1">
            <img src={Lips1} alt="Uśmiech odsłaniający zęby" />
          </Styled.Image>
          <Styled.Image to="/usta#2">
            <img src={Lips2} alt="Usta ułożone w dziubek" />
          </Styled.Image>
          <Styled.Image to="/usta#3">
            <img src={Lips3} alt="Usta zaciśnięte" />
          </Styled.Image>
          <Styled.Image to="/usta#4">
            <img
              src={Lips4}
              alt="Usta wykrzywione i pomalowane różową pomadką"
            />
          </Styled.Image>
          <Styled.Image to="/usta#5">
            <img
              src={Lips5}
              alt="Usta lekko uśmiechniete z uniesionym kącikiem ust"
            />
          </Styled.Image>
          <Styled.Image to="/usta#6">
            <img src={Lips6} alt="Usta przygryzające dolną wargę" />
          </Styled.Image>
        </Styled.Images>
      </Styled.Container>
    </Styled.Content>
  );
};

export default Quiz;
