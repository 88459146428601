import React, { FunctionComponent } from "react";
import * as Styled from "./Button.styled";
import { ButtonLink } from "./Button.styled";

interface ButtonType {
  text: string;
  link?: string;
  background?: boolean;
  disabled?: boolean;
  bigger?: boolean;
  onClick?: () => void;
}

const Button: FunctionComponent<ButtonType> = ({
  text,
  link,
  background = true,
  disabled = false,
  bigger = false,
  onClick,
}) => {
  return (
    <>
      {link && (
        <Styled.ButtonLink
          href={link}
          background={background}
          onClick={onClick}
          disabled={disabled}
          bigger={bigger}
        >
          {text}
        </Styled.ButtonLink>
      )}
      {!link && (
        <Styled.Button
          background={background}
          onClick={onClick}
          disabled={disabled}
          bigger={bigger}
        >
          {text}
        </Styled.Button>
      )}
    </>
  );
};

export default Button;
