import {
  css,
  FlattenInterpolation,
  FlattenSimpleInterpolation,
} from 'styled-components';
import Variables from './Variables';

export const mediaUp = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>,
) => css`
  @media only screen and (min-width: ${media}) {
    ${children}
  }
`;

export const mediaDown = (
  media: string,
  children: string | FlattenSimpleInterpolation | FlattenInterpolation<any>,
) => css`
  @media only screen and (max-width: ${media}) {
    ${children}
  }
`;

export const AppContainer = css`
  display: flex;
  flex: 0 0 100%;
  margin: 0 auto;
  max-width: ${Variables.maxWidth};
  padding-left: ${Variables.padding.xl};
  padding-right: ${Variables.padding.xl};
  ${mediaDown(
    Variables.breakpoints.ultra,
    css`
      padding-left: ${Variables.padding.lg};
      padding-right: ${Variables.padding.lg};
    `,
  )}

  ${mediaDown(
    Variables.breakpoints.xl,
    css`
      padding-left: ${Variables.padding.md};
      padding-right: ${Variables.padding.md};
    `,
  )}
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      padding-left: ${Variables.padding.sm};
      padding-right: ${Variables.padding.sm};
    `,
  )}
`;
