import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { AppContainer, mediaDown } from "../../styles/Mixins";

export const Nav = styled.div`
  width: 100%;
  position: absolute;
  top: 5rem;
  left: 0;
  z-index: 100;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      top: 4rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      top: 3rem;
    `
  )};
`;

export const Container = styled.div`
  ${AppContainer};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      justify-content: center;
    `
  )};
`;

export const LogoWrapper = styled.div`
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      svg {
        width: 40vw;
      }
    `
  )};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;

  a:first-of-type {
    margin-right: 2.4rem;
  }

  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      display: none;
    `
  )};
`;
