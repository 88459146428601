import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { AppContainer, mediaDown } from "../../styles/Mixins";
import { Link } from "gatsby";

export const Content = styled.section`
  background-color: ${Variables.colors.black};
`;

export const Container = styled.div`
  ${AppContainer};
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 10rem;
  padding-bottom: 10rem;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      padding-top: 6rem;
      padding-bottom: 6rem;
    `
  )};
`;

export const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 0 10rem;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      margin: 0 0 5rem;
    `
  )};
`;

export const Quiz = styled.p`
  font-size: 24rem;
  color: ${Variables.colors.purple};
  font-weight: ${Variables.fontWeight.bold};
  text-transform: uppercase;
  z-index: 0;
  text-align: center;
  line-height: 1;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      font-size: 18rem;
    `
  )};
`;

export const Title = styled.h2`
  position: absolute;
  display: grid;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-weight: ${Variables.fontWeight.bold};
  color: ${Variables.colors.white};
  font-size: 3.5rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;

  span {
    color: ${Variables.colors.teal};
    font-weight: ${Variables.fontWeight.regular};
    font-size: 2.8rem;
  }

  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      font-size: 3rem;
      span {
        font-size: 2.4rem;
      }
    `
  )};
`;

export const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10rem;
  row-gap: 8rem;
  ${mediaDown(
    Variables.breakpoints.xl,
    css`
      gap: 10rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.lg,
    css`
      gap: 8rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      gap: 2rem;
      row-gap: 4rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 3rem;
      row-gap: 4rem;
    `
  )};
`;

export const Image = styled(Link)`
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  min-width: 32.5rem;
  min-height: 22rem;
  border: 5px solid ${Variables.colors.white};
  transition: ${Variables.transition};
  transition-property: border-color, box-shadow;
  cursor: pointer;

  img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  &:hover {
    border: 5px solid ${Variables.colors.teal};
    box-shadow: 0 0 84px rgba(8, 236, 177, 0.2);
  }

  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      border-radius: 20px;
      border: 2px solid ${Variables.colors.white};
      min-width: 16rem;
      min-height: 11rem;
      &:hover {
        border: 2px solid ${Variables.colors.teal};
      }
    `
  )};
`;
