import React, { FunctionComponent, useEffect, useState } from "react";
import * as Styled from "./AgeGate.styled";
import Button from "../Button/Button";

type Props = {};

const AgeGate: FunctionComponent<Props> = (props) => {
  const [isAgeConfirmed, setIsAgeConfirmed] = useState<boolean>(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    const ageConfirmed = sessionStorage.getItem("age_confirmed");
    if (ageConfirmed) {
      setIsAgeConfirmed(true);
    } else {
      setIsAgeConfirmed(false);
    }
  }, []);

  const confirmAge = () => {
    setIsAgeConfirmed(true);
    sessionStorage.setItem("age_confirmed", "true");
  };

  return (
    <>
      {!isAgeConfirmed && (
        <Styled.Content isAgeConfirmed={isAgeConfirmed}>
          <Styled.Container>
            <Styled.Title>
              Potwierdź
              <br />
              zanim zadziałasz
            </Styled.Title>
            <Styled.Text>
              Witaj na stronie dla pełnoletnich osób zainteresowanych ofertą BAT
              Polska Trading sp.&nbsp;z&nbsp;o.o.
            </Styled.Text>
            <Styled.Text>Potwierdzam, że mam ukończone 18 lat.</Styled.Text>
            <Styled.ButtonWrapper>
              <Button text={"Tak, potwierdzam"} onClick={() => confirmAge()} />
              <Button
                text={"Nie"}
                onClick={() => setShowMessage(true)}
                background={false}
              />
              {showMessage && (
                <Styled.Message>
                  Strona jest dostępna wyłącznie dla osób pełnoletnich.
                </Styled.Message>
              )}
            </Styled.ButtonWrapper>
            <Styled.Text>
              Ta strona należy do BAT Polska Trading Sp.&nbsp;z&nbsp;o.o.
              Dedykowana jest dla pełnoletnich użytkowników wyrobów nikotynowych
              BAT Polska Trading sp.&nbsp;z&nbsp;o.o. Wszystkie wyroby należące
              do BAT Polska szkodzą zdrowiu i&nbsp;są uzależniające.
            </Styled.Text>
          </Styled.Container>
        </Styled.Content>
      )}
    </>
  );
};

export default AgeGate;
