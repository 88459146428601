import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { mediaDown } from "../../styles/Mixins";

type ButtonProps = {
  background: boolean;
  disabled: boolean;
  bigger: boolean;
};

const ButtonBase = css<ButtonProps>`
  width: fit-content;
  padding: 2rem 4rem;
  transition: ${Variables.transition};
  transition-property: color, background-color, border-color;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: ${Variables.fontWeight.bold};
  background-color: transparent;
  color: ${Variables.colors.white};
  border: 2px solid ${Variables.colors.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20rem;
  cursor: pointer;

  &:hover {
    color: ${Variables.colors.teal};
    background-color: ${Variables.colors.purpleDark};
  }
  ${(props) =>
    props.background &&
    css`
      color: ${Variables.colors.teal};
      background-color: ${Variables.colors.purple};
      border: 2px solid ${Variables.colors.purple};

      &:hover {
        background-color: ${Variables.colors.purpleDark};
        border-color: ${Variables.colors.purpleDark};
      }
    `}
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      border: 2px solid ${Variables.colors.darkGray};
      background-color: ${Variables.colors.darkGray};
      color: ${Variables.colors.gray};
    `}
 
    ${mediaDown(
    Variables.breakpoints.md,
    css`
      min-width: unset;
      padding: 2rem 3rem;
    `
  )};
  ${(props) =>
    props.bigger &&
    css`
      font-size: 2rem;
      padding: 3rem 6rem;
      ${mediaDown(
        Variables.breakpoints.md,
        css`
          padding: 2rem 4rem;
        `
      )};
    `}
`;

export const ButtonLink = styled.a<ButtonProps>`
  ${ButtonBase};
`;

export const Button = styled.button<ButtonProps>`
  ${ButtonBase};
`;
