import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { mediaDown } from "../../styles/Mixins";
import DesktopBg from "../../images/lips-background.jpg";
import MobileBg from "../../images/lips-background-mobile.jpg";

export const Content = styled.header`
  position: relative;
  width: 100%;
  aspect-ratio: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("${DesktopBg}") center no-repeat;
  background-size: cover;
  ${mediaDown(
    Variables.breakpoints.xxl,
    css`
      height: 100vh;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      background: url("${MobileBg}") center no-repeat;
      background-size: cover;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      padding-top: 20vh;
      align-items: flex-start;
    `
  )};
`;

export const TextWrapper = styled.div``;

const TitleBase = css`
  color: ${Variables.colors.white};
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: ${Variables.fontWeight.bold};
  text-shadow: 0 0 40px rgba(31, 29, 34, 0.5);
  span {
    color: ${Variables.colors.teal};
  }
`;

export const Title = styled.h1`
  ${TitleBase};
  font-size: 10rem;
  margin-bottom: 1rem;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      font-size: 8rem;
      margin-bottom: 2rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      font-size: 6.4rem;
    `
  )};
`;

export const Subtitle = styled.h2`
  ${TitleBase};
  font-size: 6rem;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      font-size: 4rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      font-size: 2.6rem;
    `
  )};
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      width: 90vw;
      bottom: 10%;
      a {
        width: 100%;
      }
    `
  )};
`;
