import React from "react";
import * as Styled from "./Navigation.styled";
import Button from "../Button/Button";
import Logo from "../Logo/Logo";

interface NavProps {}

const Navigation: React.FunctionComponent<NavProps> = () => {
  return (
    <Styled.Nav>
      <Styled.Container>
        <Styled.LogoWrapper>
          <Logo type={"light"} />
        </Styled.LogoWrapper>
        <Styled.ButtonsWrapper>
          <Button text="Wypełnij quiz" link={"#quiz"} />
          <Button
            text="Poznaj kickstart"
            link={"https://www.velo.com/pl/pl/poznaj-velo"}
            background={false}
          />
        </Styled.ButtonsWrapper>
      </Styled.Container>
    </Styled.Nav>
  );
};

export default Navigation;
