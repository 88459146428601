import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { mediaDown } from "../../styles/Mixins";
import Background from "../../images/age-gate.jpg";

type Props = {
  isAgeConfirmed: boolean;
};

export const Content = styled.section<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  visibility: visible;
  transition: ${Variables.transition};
  transition-property: opacity, visibility;
  z-index: 3000;
  ${(p: Props) =>
    p.isAgeConfirmed &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url("${Background}") no-repeat;
  background-size: cover;
  background-position: right;
  min-width: 96rem;
  padding: 10rem 6rem 7rem 6rem;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      min-width: 60rem;
      padding: 10rem 4rem 7rem 4rem;
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
        url("${Background}") no-repeat;
      background-size: cover;
      background-position: right;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      width: 100%;
      padding: 10rem 2rem;
      min-width: unset;
    `
  )};
`;

export const Title = styled.h2`
  color: ${Variables.colors.white};
  font-size: 4.8rem;
  font-weight: ${Variables.fontWeight.bold};
  text-transform: uppercase;
  line-height: 1.2;
  margin-bottom: 2.4rem;
`;

export const Text = styled.p`
  color: ${Variables.colors.white};
  max-width: 52rem;
  &:nth-of-type(1) {
    font-size: 2rem;
  }
  &:nth-of-type(2) {
    font-size: 2.4rem;
    margin-top: 2rem;
    font-weight: ${Variables.fontWeight.bold};
  }
  &:nth-of-type(3) {
    font-size: 1.4rem;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 2rem;
  margin: 3rem 0 8rem;
  button:first-of-type {
    margin-right: 3rem;
  }
  button:last-of-type {
    min-width: unset;
  }
`;

export const Message = styled.p`
  position: absolute;
  bottom: -2rem;
  left: 0;
  font-weight: ${Variables.fontWeight.medium};
  color: ${Variables.colors.white};
  font-size: 2rem;
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      bottom: -2rem;
      font-size: 1.8rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.md,
    css`
      bottom: -5rem;
      font-size: 1.7rem;
    `
  )};
`;
