import styled, { css } from "styled-components";
import Variables from "../../styles/Variables";
import { AppContainer, mediaDown } from "../../styles/Mixins";

export const Content = styled.footer`
  background-color: ${Variables.colors.white};
`;

export const Container = styled.div`
  ${AppContainer};
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-top: 7rem;
  padding-bottom: 4rem;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      padding-top: 4rem;
      padding-bottom: 2rem;
    `
  )};
`;

export const Wrapper = styled.div`
  border-top: 1px solid ${Variables.colors.gray};
  border-bottom: 1px solid ${Variables.colors.gray};
  padding: 5rem 0;
  margin: 5rem 0;
  display: grid;
  grid-template-columns: 2.5fr 3fr;
  justify-content: space-between;
  ${mediaDown(
    Variables.breakpoints.xl,
    css`
      grid-template-columns: 1fr;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      padding: 3rem 0;
      margin: 3rem 0;
    `
  )};
`;

export const InfoLeft = styled.div`
  padding-right: 10rem;
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      padding-right: 0;
      br {
        display: none;
      }
      p:first-of-type {
        padding-bottom: 2rem;
      }
    `
  )};
`;

export const InfoRight = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10rem;
  ${mediaDown(
    Variables.breakpoints.xl,
    css`
      margin-top: 7rem;
    `
  )};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      margin-top: 5rem;
      grid-template-columns: 1fr;
      gap: 6rem;
    `
  )};
`;

export const Column = styled.div``;

export const Text = styled.p`
  color: ${Variables.colors.black};
  font-weight: ${Variables.fontWeight.regular};
  line-height: 1.6;
  padding: 0.5rem 0;
  strong {
    font-weight: ${Variables.fontWeight.bold};
  }
  &.phone {
    font-size: 3.2rem;
    line-height: 1;
    font-weight: ${Variables.fontWeight.bold};
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    color: ${Variables.colors.black};
    font-weight: ${Variables.fontWeight.bold};
    line-height: 1.6;
    padding: 0;
    transition: ${Variables.transition};
    transition-property: color;
    &:hover {
      color: ${Variables.colors.purpleDark};
    }
  }
  a {
    transition: ${Variables.transition};
    transition-property: color;
    line-height: 1;
    font-weight: ${Variables.fontWeight.bold};
    &.purple {
      color: ${Variables.colors.purple};
    }
    &:hover {
      color: ${Variables.colors.purpleDark};
    }
  }
`;

export const Label = styled.p`
  font-size: 1.6rem;
  color: ${Variables.colors.purple};
  margin-bottom: 2.4rem;
  font-weight: ${Variables.fontWeight.bold};
  ${mediaDown(
    Variables.breakpoints.sm,
    css`
      margin-bottom: 1.4rem;
    `
  )};
`;
